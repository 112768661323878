import { render, staticRenderFns } from "./CreateOrderModal.vue?vue&type=template&id=673e0978&scoped=true&"
import script from "./CreateOrderModal.vue?vue&type=script&lang=js&"
export * from "./CreateOrderModal.vue?vue&type=script&lang=js&"
import style0 from "./CreateOrderModal.vue?vue&type=style&index=0&id=673e0978&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "673e0978",
  null
  
)

export default component.exports